import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { sendOpenUrl } from "../../utils/interactWithFlutter";
import { useUserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
`;

const ModalContent = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 80%;
  width: 400px;
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-size: 0.9rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const MaintenanceModal = () => {
  const { state } = useUserContext();
  const { profile } = state;
  const [showModal, setShowModal] = useState(true);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { t } = useTranslation();
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>{t("서버 점검 공지")}</ModalTitle>
        <p>{t("지속되는 사용자 증가로 인한 서버 부하 문제로 인해")}</p>
        <p>
          {t("00:00부터 04:00까지 서버 확장이전을 위한 작업 중에 있습니다.")}
        </p>
        <p>
          {t(
            "작업 시간 동안 앱 사용이 불가하며, 최대한 빠르게 작업을 완료하도록 하겠습니다."
          )}
        </p>
        <p>
          {t(
            "다만, 작업 상황에 따라 시간이 지연될 수 있는 점 양해 부탁드립니다."
          )}
        </p>
        <p>{t("불편을 드려 죄송합니다.")}</p>
      </ModalContent>
    </ModalOverlay>
  );
};

export default MaintenanceModal;
