import React from "react";
import styled from "styled-components";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useTranslation } from "react-i18next";

const ModalContent = styled.div`
  padding: 20px;
  max-height: 50vh;
  overflow-y: auto;
  color: ${({ theme }) => theme.text};
`;

const ModalTitle = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const LanguageItem = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
`;

const LanguageSelectionModal = ({ isOpen, onDismiss, onSelectLanguage }) => {
  const { t, i18n } = useTranslation();

  const languages = [
    { code: "ko", name: "한국어" },
    { code: "en", name: "English" },
    { code: "ja", name: "日本語" },
  ];

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onDismiss}
      snapPoints={({ maxHeight }) => [maxHeight * 0.4]}
      defaultSnap={({ lastSnap, snapPoints }) => snapPoints[0]}
    >
      <ModalContent>
        <ModalTitle>{t("언어 선택")}</ModalTitle>
        {languages.map((lang) => (
          <LanguageItem
            key={lang.code}
            onClick={() => {
              onSelectLanguage(lang.code);
              onDismiss();
            }}
          >
            {lang.name}
          </LanguageItem>
        ))}
      </ModalContent>
    </BottomSheet>
  );
};

export default LanguageSelectionModal;
