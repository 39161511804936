export const sendMessage = (handlerName, message) => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(handlerName, message);
  }
  window.Flutter?.postMessage(message);
};

export const sendOpenUrl = (url) => {
  sendMessage("openUrl", url);
  window.Flutter?.postMessage("openUrl: " + url);
};

export const sendUserInfo = (userInfo) => {
  sendMessage("user", userInfo);
  if (userInfo === "signout") {
    window.Flutter?.postMessage("signout");
  } else if (userInfo === "deleteuser") {
    window.Flutter?.postMessage("deleteuser");
  }
};

export const getPhoto = (photoType) => {
  sendMessage("photo", photoType);
  window.Flutter?.postMessage("photo");
};

export const setNoti = (
  action,
  notiId,
  notiContent,
  afterTime = null,
  specificTime = null
) => {
  sendMessage("noti", {
    action,
    notiId,
    notiContent,
    afterTime,
    specificTime,
    notiTitle: "베베스냅 알림",
  });
};

export const setLanguage = (language) => {
  sendMessage("language", language);
};

export const setThemeMode = (mode) => {
  sendMessage("theme", mode);
};

export const showReview = () => {
  sendMessage("requestReview", "");
};


export const clearCache = () => {
  sendMessage("clearCache", "");
}